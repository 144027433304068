// @file Exports the full tailwind resolved config

import resolveConfig from 'tailwindcss/resolveConfig'
// eslint-disable-next-line no-restricted-imports
import tailwindConfig from '../../../tailwind.config'

interface ColorConfig {
  [key: string]: ColorConfig | string
}

interface FlattenedColorConfig {
  [key: string]: string
}

/**
 * This function is used to flatten a nested object into a single level object.
 * It uses recursion to handle nested objects.
 *
 * @param obj - The object to be flattened. It can be an object with string values or nested objects.
 * @param parentKey - The key of the parent object. It's used to construct the full key for nested objects.
 * @returns A single level object with the keys being the path to the original value in the input object.
 */
function flattenObject(obj: ColorConfig, parentKey: string = ''): FlattenedColorConfig {
  const SEPARATOR = '-'
  let flattenedObject: FlattenedColorConfig = {}

  // Iterate over each key-value pair in the object
  for (const key in obj) {
    // Construct the full key for the current value
    const fullKey = parentKey !== '' ? `${parentKey}${SEPARATOR}${key}` : key
    const value = obj[key]

    // If the value is an object, recursively flatten it
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      flattenedObject = { ...flattenedObject, ...flattenObject(value, fullKey) }
    } else {
      // If the value is not an object, add it to the flattened object
      flattenedObject[fullKey] = value as string
    }
  }

  return flattenedObject
}

export const tailwindFullConfig = resolveConfig(tailwindConfig as any)
export const tailwindFlattenedColors = flattenObject(tailwindFullConfig.theme.colors as ColorConfig)
