// @file Class names for slideshow
const TITLE = {
  sm: 'slideshow-title-sm',
  md: 'slideshow-title-md',
  lg: 'slideshow-title-lg',
  xl: 'slideshow-title-xl',
  '2xl': 'slideshow-title-2xl',
}

const BODY = {
  xs: 'slideshow-body-xs',
  sm: 'slideshow-body-sm',
  md: 'slideshow-body-md',
  lg: 'slideshow-body-lg',
  xl: 'slideshow-body-xl',
  '2xl': 'slideshow-body-2xl',
}

const CUSTOM_PROPERTIES_LABEL = {
  xs: 'slideshow-body-xs',
  sm: 'slideshow-body-sm',
  md: 'slideshow-body-md',
  lg: 'slideshow-body-lg',
  xl: 'slideshow-body-xl',
}

const SUBHEADING = {
  md: 'slideshow-subheading-md',
  lg: 'slideshow-subheading-lg',
  xl: 'slideshow-subheading-xl',
}

const CUSTOM_PROPERTIES_VALUE = BODY

const HEADING = {
  sm: 'slideshow-heading-sm',
  md: 'slideshow-heading-md',
  lg: 'slideshow-heading-lg',
  xl: 'slideshow-heading-xl',
  '2xl': 'slideshow-heading-2xl',
}

export { TITLE, BODY, CUSTOM_PROPERTIES_LABEL, SUBHEADING, CUSTOM_PROPERTIES_VALUE, HEADING }
